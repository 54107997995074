import { inject } from 'aurelia-framework';
import { Client } from '../../api/client';
import { FlashService } from '../../flash/flash-service';
import { ConfigurationLoader } from '../../form/loader/configuration-loader';
import { FormServiceFactory } from '../../form/service/form-service-factory';
import { DialogController } from 'aurelia-dialog';

@inject(Client, FlashService, ConfigurationLoader, FormServiceFactory, DialogController)
export class CalendarFormDialog {
    form;
    constructor(client, flash, configurationLoader, formServiceFactory, dialogController) {
        this.client = client;
        this.flash = flash;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;
        this.dialogController = dialogController;

        this.submit = this.submit.bind(this);
    }

    async activate(context) {
        const { resource, start, end } = context.data;
        const data = {
            guideLink: { id: resource },
            startDate: start.value,
            endDate: end.value,
            id: resource,
        };
        this.formConfig = await this.configurationLoader.get('tour-guide-tourism/manage-guide-availability', data);
        const fields = this.formConfig.fields;
        if (fields && fields.length > 1) {
            fields[1].default = {
                id: resource,
                modelId: 'tour-guide-tourism/guide'
            };

            fields[5].default = start.value;
            fields[6].default = end.value;
        }
    }

   async submit() {
        const data = this.form.formService.getValue();
        const formData = await this.client.post('tour-guide-tourism/manage-guide-availability', data);
        this.dialogController.ok(formData);
        this.flash.success('sio.successful');
   }
}
